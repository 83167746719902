<template>
  <VRow
    align="center"
    no-gutters
    class="mb-6"
  >
    <VCol
      :cols="desktopBreakpoints ? 4 : 12"
    >
      <h3
        class="text-body-2"
        :class="{ 'mb-3' : !desktopBreakpoints }"
      >
        {{ questionTitle }}
      </h3>
    </VCol>
    <VCol
      :cols="desktopBreakpoints ? (includeIfNotAnswered ? 7 : 8) : 12"
    >
      <div
        v-if="matrixMultipleAnswers"
        class="d-flex flex-nowrap"
        :class="{ 'flex-column align-start' : !desktopBreakpoints }"
      >
        <VCheckbox
          v-for="answer in answers"
          :key="answer.id"
          v-model="checkboxModel"
          :value="answer.id"
          :disabled="checkCheckbox(answer.id)"
          :label="!desktopBreakpoints ? answer.text : null"
          hide-details
          off-icon="$checkboxOffLight"
          class="ma-0 pa-0"
          :class="{
            'mx-auto' : desktopBreakpoints,
            'mb-3' : !desktopBreakpoints
          }"
          @click="handleAnswer"
        />
      </div>
      <template v-else>
        <div
          class="d-flex flex-column flex-md-row"
        >
          <div
            v-for="answer in answers"
            :key="answer.id"
            :class="{
              'mx-auto' : desktopBreakpoints,
              'mb-2' : !desktopBreakpoints
            }"
            class="cursor-pointer d-flex align-center"
            @click="handleAnswer(answer.id)"
          >
            <VBtn
              data-test="tt-btn"
              outlined
              fab
              color="tt-blue"
              width="24"
              height="24"
              class="ma-0"
              :ripple="$vuetify.breakpoint.lgAndUp"
            >
              <VIcon
                v-show="answer.id === radioModel"
                color="tt-blue"
                size="8"
              >
                $circleSolid
              </VIcon>
            </VBtn>
            <span
              v-if="!desktopBreakpoints"
              class="ml-2"
            >
              {{ answer.text }}
            </span>
          </div>
        </div>
      </template>
    </VCol>
    <VCol
      v-if="includeIfNotAnswered"
      :cols="desktopBreakpoints ? 1 : 12"
      :class="{ 'text-center' : desktopBreakpoints }"
    >
      <VBtn
        data-test="tt-btn"
        fab
        :width="desktopBreakpoints ? 46 : 26"
        :height="desktopBreakpoints ? 46 : 26"
        depressed
        :ripple="$vuetify.breakpoint.lgAndUp"
        :color="notAnsweredOption ? 'tt-red' : 'tt-black lighten-6'"
        :class="notAnsweredOption ? 'tt-white--text' : 'tt-black--text'"
        @click="toggleNotAnsweredOption"
      >
        <VIcon
          :size="desktopBreakpoints ? 20 : 15"
        >
          $close
        </VIcon>
      </VBtn>
      <span
        v-if="!desktopBreakpoints"
        class="ml-2"
      >
        {{ $t('cantRate') }}
      </span>
    </VCol>
  </VRow>
</template>

<script>
import * as apiService from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import { sessionId } from '@/services/cookies';

export default {
  name: 'QuestionMatrix',
  inject: ['Names'],
  props: {
    question: {
      type: Object,
      default: () => ({}),
    },
    userAnswer: {
      type: Object,
      default: () => ({}),
    },
    matrixMultipleAnswers: {
      type: Boolean,
      default: false,
    },
    includeIfNotAnswered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notAnsweredOption: false,
      checkboxModel: [],
      radioModel: null,
      currentAnswerId: null,
    };
  },
  computed: {
    desktopBreakpoints() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    answers() {
      return this.question.answers;
    },
    questionTitle() {
      return this.question.title;
    },
    maxAnswers() {
      return this.question.maxAnswers;
    },
    questionId() {
      return this.question.id;
    },
    userSurveySlug() {
      return this.$route.params.slug;
    },
    sessionId() {
      return sessionId.get();
    },
  },
  created() {
    if (Object.keys(this.userAnswer).length) {
      const { answerId, answerIds, id } = this.userAnswer;
      if (this.matrixMultipleAnswers) this.checkboxModel = answerIds || [];
      if (!this.matrixMultipleAnswers) this.radioModel = answerId || null;
      this.currentAnswerId = id;
    }
    if (this.userAnswer.notAnswered) this.notAnsweredOption = this.userAnswer.notAnswered;
  },
  methods: {
    checkCheckbox(id) {
      return !this.checkboxModel.includes(id) && this.checkboxModel.length >= this.maxAnswers;
    },
    async handleAnswer(id) {
      if (this.notAnsweredOption) this.notAnsweredOption = !this.notAnsweredOption;
      const payload = {
        questionId: this.questionId,
      };
      if (this.matrixMultipleAnswers) {
        payload.answerIds = this.checkboxModel;
      }
      if (!this.matrixMultipleAnswers) {
        if (id === this.radioModel) {
          await this.deleteAnswerWithId();
          this.radioModel = null;
          payload.answerId = this.radioModel;
          this.$emit('answer', payload);
          return;
        }
        this.radioModel = id;
        payload.answerId = this.radioModel;
      }
      await this.updateAnswer(payload);
      this.$emit('answer', payload);
    },
    async toggleNotAnsweredOption() {
      this.notAnsweredOption = !this.notAnsweredOption;
      const payload = {
        notAnswered: this.notAnsweredOption,
        questionId: this.questionId,
        answerId: this.radioModel,
        answerIds: this.checkboxModel,
      };
      if (!this.notAnsweredOption) {
        await this.deleteAnswerWithId();
        this.$emit('answer', payload);
        return;
      }
      if (this.notAnsweredOption) {
        this.checkboxModel = [];
        this.radioModel = null;
      }
      await this.updateAnswer(payload);
      this.$emit('answer', payload);
    },
    async updateAnswer(payload) {
      const {
        answerId, answerIds, notAnswered, questionId,
      } = payload;

      try {
        const response = await apiService.answerUpdate(this.userSurveySlug, {
          questionId,
          answerId,
          answerIds,
          notAnswered,
        }, {
          headers: { 'x-session-id': this.sessionId },
        });

        if (response?.data?.userAnswer) {
          this.currentAnswerId = response.data.userAnswer.id;
        }
      } catch (err) {
        if (err.response.data.message === 'survey_already_finished') {
          this.$router.replace({
            name: this.Names.R_USER_SURVEY_FINISHED,
            params: { slug: this.userSurveySlug },
          });
          return;
        }
        errorHandler(err);
      }
    },
    async deleteAnswerWithId() {
      const { userSurveySlug, questionId, currentAnswerId: id } = this;
      const payload = { userSurveySlug, questionId };

      try {
        await apiService.answerWithIdDelete(id, payload);
      } catch (err) {
        errorHandler(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .v-icon {
    color: #00A0F2;
    caret-color: #00A0F2;
  }
  ::v-deep .v-input--radio-group__input {
    justify-content: space-around;
  }
  ::v-deep .v-input--selection-controls__input {
    margin-right: 0;
  }
  ::v-deep .v-label {
    margin-left: 12px;
    color: #0B1218;
  }
  ::v-deep .v-btn--outlined {
    border-width: 2px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>
