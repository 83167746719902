<template>
  <div class="mb-4">
    <div
      class="mb-6 custom-title"
      v-html="matrixTitle"
    />
    <VRow
      v-if="desktopBreakpoints"
      no-gutters
      justify="end"
      class="mb-6"
    >
      <VCol
        :cols="includeIfNotAnswered ? 7 : 8"
        class="d-flex align-start flex-nowrap"
      >
        <p
          v-for="(text, index) in answerHeaders"
          :key="index"
          style="max-width: 60px;"
          class="text-body-2 text-center mx-auto mb-0"
        >
          {{ text }}
        </p>
      </VCol>
      <VCol
        v-if="includeIfNotAnswered"
        cols="1"
      >
        <p
          class="text-body-2 text-center mb-0"
        >
          {{ $t('cantRate') }}
        </p>
      </VCol>
    </VRow>
    <QuestionMatrix
      v-for="question in filteredQuestions"
      :key="question.id"
      :question="question"
      :user-answer="userAnswer(question.id)"
      :matrix-multiple-answers="multipleAnswers"
      :include-if-not-answered="includeIfNotAnswered"
      @answer="updateAnswer"
    />
    <div
      v-if="commentable"
      class="my-3"
    >
      <h2 class="text-h6 mb-6 font-weight-medium">
        {{ $t('commentOnYourChoice') }}
      </h2>
      <VTextarea
        v-model.trim="commentText"
        class="custom-textarea"
        no-resize
        solo
        flat
        hide-details
        height="96"
        :placeholder="$t('text')"
        background-color="tt-black lighten-6"
        @input="debouncedCommentHandler"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import * as apiService from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import QuestionMatrix from '@/components/questions/QuestionMatrix.vue';

export default {
  name: 'MatrixBlock',
  components: { QuestionMatrix },
  props: {
    title: {
      type: String,
      default: '',
    },
    questions: {
      type: Array,
      default: () => ([]),
    },
    userAnswers: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      commentText: '',
      answersCounts: [],
    };
  },
  computed: {
    filteredQuestions() {
      return this.questions.filter((question) => question.ord > 1);
    },
    matrixTitle() {
      return this.questions[0].title;
    },
    multipleAnswers() {
      return this.questions[0].maxAnswers > 1;
    },
    includeIfNotAnswered() {
      return this.questions[0].includeIfNotAnswered;
    },
    answerHeaders() {
      const headers = this.questions[1].answers.map((answer) => answer.text);
      return headers;
    },
    desktopBreakpoints() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    commentable() {
      return this.questions[0].commentable;
    },
    questionId() {
      return this.questions[0].id;
    },
    slug() {
      return this.$route.params.slug;
    },
    questionComment() {
      return this.questions[0]?.comment?.body || '';
    },
    isRequiredQuestion() {
      return this.questions[0].required;
    },
    canMoveOn() {
      if (this.answersCounts.length) {
        return this.answersCounts.every((item) => item.count > 0);
      }
      return false;
    },
  },
  watch: {
    canMoveOn(val) {
      if (this.isRequiredQuestion) {
        if (val) {
          this.$emit('update-required', this.questionId);
        } else {
          this.$emit('remove-required', this.questionId);
        }
      }
    },
  },
  created() {
    this.debouncedCommentHandler = debounce(this.sendComment, 250);

    if (this.questionComment.length > 0) {
      this.commentText = this.questionComment;
    }

    this.answersCounts = this.filteredQuestions.map((item) => ({
      id: item.id,
      count: this.userAnswersCount(item.id),
    }));

    if (this.canMoveOn && this.isRequiredQuestion) {
      this.$emit('update-required', this.questionId);
    }
  },
  methods: {
    userAnswer(questionId) {
      const answersArr = this.userAnswers.filter((answers) => answers.questionId === questionId);
      const [userAnswer] = answersArr;
      if (answersArr.length >= 1) {
        userAnswer.answerIds = answersArr.map((answer) => answer.answerId)
          .filter((id) => id !== null);
      }
      return userAnswer;
    },
    async sendComment() {
      const { slug, questionId } = this;

      const payload = {
        user_survey_slug: slug,
        question_id: questionId,
        body: this.commentText,
      };

      try {
        await apiService.questionCommentPost({
          params: payload,
        });
      } catch (err) {
        errorHandler(err);
      }
    },
    updateAnswer(payload) {
      const {
        answerId, answerIds, notAnswered, questionId,
      } = payload;

      const newCount = this.multipleAnswers
        ? (answerIds.length + (notAnswered || 0)) : (answerId + (notAnswered || 0));

      const indexOfItem = this.answersCounts.findIndex((item) => item.id === questionId);

      this.answersCounts.splice(indexOfItem, 1, {
        id: questionId,
        count: newCount,
      });
    },
    userAnswersCount(questionId) {
      const findedAnswers = this.userAnswer(questionId);
      let answersCount = 0;
      const notAnsweredCount = findedAnswers?.notAnswered || 0;
      if (findedAnswers && this.multipleAnswers) {
        answersCount = findedAnswers.answerIds?.length || 0;
      }
      if (findedAnswers && !this.multipleAnswers) {
        answersCount = findedAnswers.answerId || 0;
      }
      return answersCount + notAnsweredCount;
    },
  },
};
</script>
